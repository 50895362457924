export const environment = {
    production: false,
    defaultLang: 'ca',
    apiUrl: 'https://escher.upc.edu/api',
    logo: "./assets/logos/logo_upc.svg",
    multiChat: true,
    hasLogin: true,
    hasModifyFilesIndexed: true,
    hasChatOpen: false,
    customOptions: ['feedback_available', 'attachment_available'],
    tokenPublicUser: 'cHVibGljOmgxVmElbVVid3ZyVjBX',
    modifyPrompt: true
}