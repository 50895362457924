<div class="mb-2">
    <div>
        <label class="label">{{label}}</label>
    </div>
</div>
<div class="field">
    <div class="field-body">
        <div class="field has-addons has-addons-right">
            <p class="control">
                <select [(ngModel)]="currentLang" class="input select">
                    @for (lang of langs; let i = $index; track i) {
                        <option [value]="lang" [selected]="lang === currentLang">{{lang}}</option>
                    }
                </select>
            </p>
            <p class="control is-expanded">
                <input class="input is-fullwidth" [(ngModel)]="text[currentLang]" type="text" />
            </p>
        </div>
    </div>
</div>