import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input-text-multi-lang',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, FormsModule, TranslateModule],
  templateUrl: './input-text-multi-lang.component.html',
  styleUrl: './input-text-multi-lang.component.scss'
})
export class InputTextMultiLangComponent implements OnChanges {
  @Input() label: string;
  @Input() key: string;
  @Input() value: string;

  currentLang = '';

  text;

  langs = ['_', 'ca', 'es', 'en', 'de', 'fr', 'it'];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.loadText();
  }

  ngOnInit() {
    this.loadText();
  }

  loadText() {
    try {
      const parsedText = JSON.parse(this.value);

      if (parsedText) {
        this.currentLang = this.langs[0];
        this.text = parsedText;
      }

    } catch (e) {
      this.currentLang = '_';
      this.text = {
        _: this.value,
        ca: '',
        es: '',
        en: '',
        de: '',
        fr: '',
        it: ''
      };
    }
  }

  getCurrentText() {
    return {key: this.key, value: JSON.stringify(this.text)};
  }
}
