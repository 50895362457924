import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Renderer2 } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faBooks, faCabinetFiling, faCircleExclamation, faFileLines } from '@fortawesome/pro-duotone-svg-icons';
import { CustomService } from '../../core/services/custom.service';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environment/environment';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-demo-chat',
  templateUrl: './demo-chat.component.html',
  styleUrls: ['./demo-chat.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DemoChatComponent {
  loading = true;

  faSpinner = faSpinner;
  faBack = faChevronLeft;

  currentChat;
  isOpenChat = false;

  constructor(
    private route: ActivatedRoute,
    private _customService: CustomService,
    private renderer: Renderer2,
    private _authService: AuthService
  ) { 
  }

  async ngOnInit() {
    this.loading = true;

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      localStorage.setItem('selectedChat', id);
      this.isOpenChat = true;
    }

    this.currentChat = await this._customService.getValue();

    const meta = document.querySelector('meta[name="chat-config"]');

    if (meta) {
      this.renderer.removeChild(document.head, meta);
    }

    const metaLang = document.querySelector('meta[name="chat-lang"]') as Element;
    if (metaLang) {
      this.renderer.removeChild(document.head, metaLang);
    }

    setTimeout(() => {
      const metaTag = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaTag, 'name', 'chat-config');


      if(this._authService.isAuthenticated() && this._authService.getUser().role === 'public') {
        const bearerToken = this._authService.getUser().access_token;

        this.renderer.setAttribute(metaTag, 'content', `{"apiUrl": "${this.currentChat.api}", "bearerToken": "${bearerToken}"}`);
      } else {
        const token = environment['tokenPublicUser'];
  
        if (token) {
          this.renderer.setAttribute(metaTag, 'content', `{"apiUrl": "${this.currentChat.api}", "token": "${token}"}`);
        } else {
          this.renderer.setAttribute(metaTag, 'content', `{"apiUrl": "${this.currentChat.api}"}`);
        }
      }
      this.renderer.appendChild(document.head, metaTag);

      const metaTagLang = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaTagLang, 'name', 'chat-lang');
      this.renderer.setAttribute(metaTagLang, 'content', `{"language": "${environment.defaultLang}"}`);
      this.renderer.appendChild(document.head, metaTagLang);
      
      this.loading = false;
    }, 100);
  }

  goBack() {
    window.history.back();
  }
}
