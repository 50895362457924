import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from "@angular/common/http";
import { catchError, tap, throwError } from "rxjs";
import { inject } from "@angular/core";
import { AuthService } from "../auth.service";
import { environment } from "../../../../environment/environment";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const _authService = inject(AuthService);

    if (environment.hasLogin) {
        if(!req.url.includes('login')) {
            const userData: any = localStorage.getItem('user');
            const accessToken = JSON.parse(userData)?.access_token;
    
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
    }

    return next(req).pipe(catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 || error.status === 403) && !req.url.includes('login')) {
          _authService.logout();
        }

        return throwError(() => error);
    }));
}
