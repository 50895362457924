<div class="container">
  <div class="columns">
    <div class="column is-flex is-justify-content-space-between">
      <h4 class="subtitle is-4" [ngClass]="{'is-clickable': hasMultiChat}" (click)="goBack()">
        @if (hasMultiChat) {
          <fa-icon class="mr-1" [icon]="faBack"></fa-icon>

          {{'CHAT_DETAIL.TITLE' | translate}} @if(currentChat) {: {{title_chat}}}
        }
      </h4>

      <div class="is-flex">
        <button class="button is-primary" [routerLink]="['/custom']">
          <fa-icon class="mr-3" [icon]="faEdit"></fa-icon>
          {{'CHAT_DETAIL.CUSTOMIZE' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="columns is-multiline">
    @if(!loading){
      @for(box of boxes; let i = $index; track i) {
        <div class="column" [ngClass]="{'is-6': !box.isLarge, 'is-12': box.isLarge}">
          <div class="is-clickable is-flex is-justify-content-center" [ngClass]="{'is-6': !box.isLarge, 'is-12': box.isLarge, 'normal-box': !box.isLarge, 'large-box': box.isLarge}" [routerLink]="['/' + box.route]">
            @if(!box.iconIsSVG) {
              <fa-duotone-icon [icon]="box.icon" class="icon" size="lg" primaryColor="var(--primary-dark)" secondaryColor="var(--primary-light)" secondaryOpacity="1" primaryOpacity="1"></fa-duotone-icon>
            } @else {
              <div>
                <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.77 18.85" style="height:40px">
                  <g id="Capa_2-2" data-name="Capa 2">
                      <g>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m11.62,5.23h-.75v-.57c0-.12-.1-.22-.22-.22h-1.56c-.12,0-.22.1-.22.22v.57h-.75v-.57c0-.53.43-.97.97-.97h1.56c.53,0,.97.43.97.97v.57Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m6.1,11.84c-.71,0-1.29-.58-1.29-1.28s.58-1.29,1.29-1.29,1.29.58,1.29,1.29-.58,1.28-1.29,1.28Zm0-1.82c-.29,0-.54.24-.54.54s.24.53.54.53.54-.24.54-.53-.24-.54-.54-.54Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m9.87,2.57c-.71,0-1.29-.58-1.29-1.29s.58-1.29,1.29-1.29,1.29.58,1.29,1.29-.58,1.29-1.29,1.29Zm0-1.82c-.29,0-.54.24-.54.54s.24.54.54.54.54-.24.54-.54-.24-.54-.54-.54Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m1.78,12.66c-.96-.03-1.78-.88-1.78-1.85s.9-1.76,1.73-1.89l.11.74c-.52.08-1.1.57-1.1,1.15s.49,1.08,1.05,1.1l-.03.75Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m9.91,11.84c-.71,0-1.29-.58-1.29-1.28s.58-1.29,1.29-1.29,1.28.58,1.28,1.29-.58,1.28-1.28,1.28Zm0-1.82c-.29,0-.54.24-.54.54s.24.53.54.53.53-.24.53-.53-.24-.54-.53-.54Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m13.71,11.84c-.71,0-1.29-.58-1.29-1.28s.58-1.29,1.29-1.29,1.29.58,1.29,1.29-.58,1.28-1.29,1.28Zm0-1.82c-.29,0-.54.24-.54.54s.24.53.54.53.54-.24.54-.53-.24-.54-.54-.54Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m17.99,12.66l-.03-.75c.56-.02,1.05-.53,1.05-1.1s-.58-1.07-1.1-1.15l.11-.74c.83.13,1.73.89,1.73,1.89s-.81,1.82-1.78,1.85Z"/>
                      <rect style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" x="9.49" y="2.13" width=".75" height="1.93"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m6.47,18.85c-.1,0-.18-.01-.23-.04-.22-.11-.29-.37-.18-.6.11-.21.27-.39.44-.58.25-.28.53-.59.75-1.12h-2.39c-1.9,0-3.44-1.54-3.44-3.44v-4.89c0-1.9,1.54-3.44,3.44-3.44h9.97c1.9,0,3.44,1.54,3.44,3.44v4.89c0,1.9-1.54,3.44-3.44,3.44h-4.48c-.7,1.16-3,2.34-3.88,2.34Zm-1.61-13.36c-1.48,0-2.69,1.21-2.69,2.69v4.89c0,1.48,1.21,2.69,2.69,2.69h2.71c.17,0,.33.08.42.21.1.14.13.31.07.47-.21.67-.51,1.11-.79,1.43.84-.35,2.07-1.05,2.47-1.81.1-.19.29-.3.5-.3h4.59c1.48,0,2.69-1.21,2.69-2.69v-4.89c0-1.48-1.21-2.69-2.69-2.69H4.86Zm2.49,10.73h0s0,0,0,0Z"/>
                      <path style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" d="m6.36,14.46c-1.9-.05-3.56-.15-4.71-.86l.39-.64c.99.61,2.55.7,4.34.75l-.02.75Z"/>
                      <rect style="stroke-width: 0px;" [ngStyle]="{'fill': 'var(--primary-dark)'}" x="5.81" y="13.43" width="1.95" height="1.31" rx=".63" ry=".63"/>
                      </g>
                  </g>
                  </svg>
              </div>
            }
  
            <h5 class="subtitle is-size-5 is-bold">{{box.title | translate}}</h5>
          </div>
        </div>
      }
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>

<div class="modal" [ngClass]="{'is-active': firstModalIsVisible}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-primary">{{'CHAT_DETAIL.INIT' | translate}}</p>
      <button class="delete" aria-label="close" (click)="firstModalIsVisible = false"></button>
    </header>
    <section class="modal-card-body">
      <p class="mb-4 has-text-danger">{{'CHAT_DETAIL.WARNING' | translate}}</p>
      <p>
        {{'CHAT_DETAIL.TEXT_MODAL_1' | translate}}
      </p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-danger" (click)="firstModalIsVisible = false; secondModalIsVisible = true">{{'GENERIC.ACCEPT' | translate}}</button>
        <button class="button" (click)="firstModalIsVisible = false">{{'GENERIC.CANCEL' | translate}}</button>
      </div>
    </footer>
  </div>
</div>

<div class="modal" [ngClass]="{'is-active': secondModalIsVisible}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-primary">{{'CHAT_DETAIL.INIT' | translate}}</p>
      <button class="delete" aria-label="close" (click)="secondModalIsVisible = false"></button>
    </header>
    <section class="modal-card-body">
      <p class="mb-4 has-text-danger">{{'CHAT_DETAIL.WARNING' | translate}}</p>
      <p>
        {{'CHAT_DETAIL.TEXT_MODAL_2' | translate}}
      </p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-danger" (click)="secondModalIsVisible = false; init()">{{'GENERIC.ACCEPT' | translate}}</button>
        <button class="button" (click)="secondModalIsVisible = false">{{'GENERIC.CANCEL' | translate}}</button>
      </div>
    </footer>
  </div>
</div>