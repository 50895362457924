import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../core/services/auth.service';
import { environment } from '../../../environment/environment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule
  ]
})
export class LoginComponent {
  image = environment.logo;

  isLoading = false;
  hasErrorLogin = false;
  errorCode = undefined;
  faSpinner = faSpinner;
  
  loginForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) { }

  async onLogin() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.hasErrorLogin = false;

      const res = await this.authService.login({ ...this.loginForm.value }).catch((e) => {
        this.isLoading = false;
        this.hasErrorLogin = true;
        this.errorCode = e.status;
      });

      if(!this.hasErrorLogin) {
        localStorage.setItem('user', JSON.stringify(res));
        this.isLoading = false;
        if(res.role === 'master') {
          this.router.navigate(['/list-chats']);
        } else if(res.role === 'public') {
          this.router.navigate(['/chat/' + res.chat_ids[0]]);
        }
      }
    }
  }
}
