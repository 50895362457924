import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environment/environment';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class HistoricService {

  constructor(
    private http: HttpClient
  ) { }

  async getHistoric(page: number, filters: any) {
    const body = {
      page,
      "per_page": 10,
      ...(filters.dateRange ? { start_date: dayjs(filters.dateRange[0]).startOf('day').format('YYYY-MM-DDTHH:mm'), end_date: dayjs(filters.dateRange[1]).endOf('day').format('YYYY-MM-DDTHH:mm')} : {})
    };

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/chat_history`, body))
  }

  async getMetrics(filters: any): Promise<any> {
    const body = {
      ...(filters.dateRange ? { start_date: dayjs(filters.dateRange[0]).startOf('day').format('YYYY-MM-DDTHH:mm'), end_date: dayjs(filters.dateRange[1]).endOf('day').format('YYYY-MM-DDTHH:mm')} : {})
    };

    const answer = await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/metrics/metrics`, body))
    return answer;
  }
}
