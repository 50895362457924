<div class="container">
  <div class="columns">
    <div class="column">
      <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
        <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
          {{'CUSTOMIZE.TITLE' | translate}}
      </h4>
    </div>
  </div>
  <div class="columns is-multiline" [formGroup]="chatForm">
    @if(!loading){
      <div class="column is-12 mb-3 is-flex is-justify-content-space-between">
        <h5 class="subtitle is-5">
          {{'CUSTOMIZE.CONFIGURATION_WIDGET' | translate}}
        </h5>

        <button class="button is-primary" (click)="resetValues(['help_text', 'title_chat', 'sender_name', 'color', 'api', 'image', 'answer_files', 'feedback_available', 'attachment_available'])">
          <fa-icon [icon]="faHammerBrush"></fa-icon>
        </button>
      </div>
      <div class="column is-5 mb-3">
        <app-input-text-multi-lang [label]="'CUSTOMIZE.INPUT_TITLE' | translate" [value]="currentChat.title_chat" key="title_chat"></app-input-text-multi-lang>
      </div>
      <div class="column is-5 mb-3">
        <app-input-text-multi-lang [label]="'CUSTOMIZE.INPUT_NAME' | translate" [value]="currentChat.sender_name" key="sender_name"></app-input-text-multi-lang>
      </div>
      <div class="column is-2 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_COLOR' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" formControlName="color" type="color" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6 mb-3">
        <app-input-text-multi-lang [label]="'CUSTOMIZE.INPUT_MESSAGE' | translate" [value]="currentChat.help_text" key="help_text"></app-input-text-multi-lang>
      </div>
      <div class="column is-6 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_API' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" [readonly]="true" formControlName="api" type="text" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_LOGO' | translate}}</label>
        </div>
        <div class="file has-name is-fullwidth">
          <label class="file-label">
            <input class="file-input" formControlName="image" type="file" accept="image/*" (change)="onFileSelected($event)"/>
            <span class="file-cta">
              <span class="file-icon">
                <fa-icon [icon]="faUpload"></fa-icon>
              </span>
              <span class="file-label">{{'CUSTOMIZE.SELECT_FILE' | translate}}</span>
            </span>
            <span class="file-name"> {{!selectedFile ? ('CUSTOMIZE.SELECT_FILE_EMPTY' | translate) : selectedFile.name}} </span>
          </label>
        </div>
      </div>
      <div class="column is-1 mb-3 container-image">
        <img
            *ngIf="chatForm.get('image').value"
            [src]="chatForm.get('image').value"
            class="img-fluid"
        />
      </div>
      @if(checkCustomOption('feedback_available')) {
        <div class="column is-2 mb-3 switch-input">
          <div class="mr-4">
            <label class="label">{{'CUSTOMIZE.INPUT_FEEDBACK' | translate}}</label>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p-inputSwitch formControlName="feedback_available"/>
              </div>
            </div>
          </div>
        </div>
      }
      @if(checkCustomOption('answer_files')) {
        <div class="column is-3 mb-3 switch-input">
          <div class="mr-4">
            <label class="label">{{'CUSTOMIZE.INPUT_SELECT_FILE' | translate}}</label>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p-inputSwitch formControlName="answer_files"/>
              </div>
            </div>
          </div>
        </div>
      }
      @if(checkCustomOption('attachment_available')) {
        <div class="column is-2 mb-3 switch-input">
          <div class="mr-4 mb-2">
            <label class="label">{{'CUSTOMIZE.INPUT_ATTACH_FILE' | translate}}</label>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p-inputSwitch formControlName="attachment_available"/>
              </div>
            </div>
          </div>
        </div>
      }

      @if(hasModifyPrompt) {
        <div class="column is-12 mb-3">
          <h5 class="subtitle is-5">
            {{'CUSTOMIZE.CONFIGURATION_BOT' | translate}}
          </h5>
        </div>
        <div class="column is-6 mb-3">
          <div class="mb-2 label-bot is-flex is-justify-content-space-between">
            <label class="label">
              {{'CUSTOMIZE.ENRICHMENT_PROMPT' | translate}}
  
              <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'enrich_query_prompt_template'"></fa-icon>
            </label>
            
  
            <button class="button is-primary" (click)="resetValues(['enrich_query_prompt_template'])">
              <fa-icon [icon]="faHammerBrush"></fa-icon>
            </button>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <textarea rows="5" class="input" formControlName="enrich_query_prompt_template"></textarea>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6 mb-3">
          <div class="mb-2 label-bot is-flex is-justify-content-space-between">
            <label class="label">
              {{'CUSTOMIZE.ENRICHMENT_RESPONSE' | translate}}
            
              <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'reply_prompt_template'"></fa-icon>
            </label>
  
            <button class="button is-primary" (click)="resetValues(['reply_prompt_template'])">
              <fa-icon [icon]="faHammerBrush"></fa-icon>
            </button>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <textarea rows="5" class="input" formControlName="reply_prompt_template"></textarea>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6 mb-3">
          <div class="mb-2 label-bot is-flex is-justify-content-space-between">
            <label class="label">
              {{'CUSTOMIZE.FRAGMENTS_FOR_RESPONSE' | translate}}
  
              <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'basic_retriever_nchunks'"></fa-icon>
            </label>
  
            <button class="button is-primary" (click)="resetValues(['basic_retriever_nchunks'])">
              <fa-icon [icon]="faHammerBrush"></fa-icon>
            </button>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" formControlName="basic_retriever_nchunks"  type="number"/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6 mb-3">
          <div class="mb-2 label-bot is-flex is-justify-content-space-between">
            <label class="label">
              {{'CUSTOMIZE.CONTEXT_MESSAGES' | translate}}
            
              <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'memory_chat_messages_limit'"></fa-icon>
            </label>
  
            <button class="button is-primary" (click)="resetValues(['memory_chat_messages_limit'])">
              <fa-icon [icon]="faHammerBrush"></fa-icon>
            </button>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" formControlName="memory_chat_messages_limit"  type="number"/>
                </p>
              </div>
            </div>
          </div>
        </div>
        @if(chatForm.get('answer_files').value) {
          <div class="column is-6 mb-3">
            <div class="mb-2 label-bot is-flex is-justify-content-space-between">
              <label class="label">
                {{'CUSTOMIZE.EXTRACTION_PROMPT' | translate}}
              
                <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'multi_item_content_extraction_prompt_template'"></fa-icon>
              </label>
  
              <button class="button is-primary" (click)="resetValues(['multi_item_content_extraction_prompt_template'])">
                <fa-icon [icon]="faHammerBrush"></fa-icon>
              </button>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <textarea rows="5" class="input" formControlName="multi_item_content_extraction_prompt_template"></textarea>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6 mb-3">
            <div class="mb-2 label-bot is-flex is-justify-content-space-between">
              <label class="label">
                {{'CUSTOMIZE.PROMPT_CROSS' | translate}}
              
                <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'multi_item_cross_result_prompt_template'"></fa-icon>
              </label>
  
              <button class="button is-primary" (click)="resetValues(['multi_item_cross_result_prompt_template'])">
                <fa-icon [icon]="faHammerBrush"></fa-icon>
              </button>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <textarea rows="5" class="input" formControlName="multi_item_cross_result_prompt_template"></textarea>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6 mb-3">
            <div class="mb-2 label-bot is-flex is-justify-content-space-between">
              <label class="label">
                {{'CUSTOMIZE.FRAGMENTS_FOR_RESPONSE_CROSS' | translate}}
  
                <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'multi_item_retriever_nchunks_per_item'"></fa-icon>
              </label>
  
              <button class="button is-primary" (click)="resetValues(['multi_item_retriever_nchunks_per_item'])">
                <fa-icon [icon]="faHammerBrush"></fa-icon>
              </button>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" formControlName="multi_item_retriever_nchunks_per_item"  type="number"/>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6 mb-3">
            <div class="mb-2 label-bot is-flex is-justify-content-space-between">
              <label class="label">
                {{'CUSTOMIZE.CONTEXT_MESSAGES_CROSS' | translate}}
  
                <fa-icon class="ml-1" [icon]="faCircleInfo" (click)="showInfoDialog = true; infoDialogText = 'multi_item_memory_chat_messages_limit'"></fa-icon>
              </label>
  
              <button class="button is-primary" (click)="resetValues(['multi_item_memory_chat_messages_limit'])">
                <fa-icon [icon]="faHammerBrush"></fa-icon>
              </button>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" formControlName="multi_item_memory_chat_messages_limit"  type="number"/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      }
      <div class="column is-12 is-flex is-justify-content-end">
        <button class="button is-primary" [disabled]="chatForm.invalid" (click)="saveChat()">
          @if(isSaving) {
            <fa-icon class="mr-3" [icon]="faSpinner" [spin]="true"></fa-icon>
          }

          {{'CUSTOMIZE.BUTTON' | translate}}
        </button>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>

<p-toast />

<p-dialog 
    [header]="'CUSTOMIZE.DESCRIPTION_CONFIGURATION' | translate" 
    [modal]="true"
    [(visible)]="showInfoDialog" 
    [style]="{ width: '50rem' }" >
      <p>
        @if(chatForm.get('answer_files').value) {
          <p [innerHTML]="'CUSTOMIZE.TYPE_CHATS' | translate"></p>
        }
        @switch (infoDialogText) {
          @case ('enrich_query_prompt_template') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C1), (C2), (C3)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_1' | translate}}
          }
          @case ('reply_prompt_template') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C1), (C2)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_2' | translate}}
          }
          @case ('basic_retriever_nchunks') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C1)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_3' | translate}}
          }
          @case ('memory_chat_messages_limit') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C1), (C2)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_4' | translate}}
          }
          @case ('multi_item_content_extraction_prompt_template') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C3)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_5' | translate}}
          }
          @case ('multi_item_cross_result_prompt_template') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C3)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_6' | translate}}
          }
          @case ('multi_item_retriever_nchunks_per_item') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C3)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_7' | translate}}
          }
          @case ('multi_item_memory_chat_messages_limit') {
            @if(chatForm.get('answer_files').value) {
              {{'CUSTOMIZE.AFFECTED_CHATS' | translate}}
              <br>
              <b>(C3)</b>
      
              <br>
              <br>
            }
            {{'CUSTOMIZE.DESCRIPTION_8' | translate}}
          }
        }

        @if(infoDialogText === 'enrich_query_prompt_template') {
          <p [innerHTML]="'CUSTOMIZE.ACCEPT_VARS' | translate"></p>
        }

        @if(infoDialogText === 'reply_prompt_template' || infoDialogText === 'multi_item_content_extraction_prompt_template' || infoDialogText === 'multi_item_cross_result_prompt_template') {
          <p [innerHTML]="'CUSTOMIZE.ACCEPT_VARS_2' | translate"></p>
        }
      </p>
</p-dialog>