<div class="container">
  <div class="columns">
    <div class="column is-flex is-justify-content-space-between">
      <h4 class="subtitle is-4">{{'LIST.TITLE' | translate}}</h4>

      <button class="button is-primary" (click)="modalCreateChat = true">
        {{'LIST.CREATE' | translate}}
      </button>
    </div>
  </div>
  <div class="columns is-multiline">
    @if(!loading){
      <div class="column is-12">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>
                {{'LIST.NAME' | translate}}
              </th>
              <th>
                {{'LIST.CREATION_DATE' | translate}}
              </th>
              <th>
                {{'LIST.ACTIONS' | translate}}
              </th>
            </tr>
          </thead>
          <tbody>
              @for (chat of chats.data; let i = $index; track i) {
                <tr class="is-clickable">
                  <td (click)="selectChat(chat)">{{ chat.name_column }}</td>
                  <td>{{ chat.created_at_column }}</td>
                  <td>
                    <fa-icon (click)="editChat = chat; chatName = chat.name_column; modalCreateChat = true;" class="mr-3 has-text-primary" [icon]="faEdit"></fa-icon>

                    <fa-icon (click)="deleteChat = chat;" class="mr-2 has-text-danger" [icon]="faTrash"></fa-icon>
                  </td>
                </tr>
              }
              @empty {
                <tr>
                  <td colspan="3" class="has-text-centered">{{'GENERIC.NO_DATA' | translate}}</td>
                </tr>
              }
          </tbody>
        </table>
      </div>
      <div class="column is-12">
        <app-pagination
          [currentPage]="currentPage"
          [totalPages]="chats.metadata.total_pages"
          (pageChange)="currentPage = $event; loadData()"></app-pagination>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-duotone-icon class="spinner-icon" [icon]="faSpinner" [spin]="true" [swapOpacity]="true"></fa-duotone-icon>
        </div>
      </div>
    }
  </div>
</div>

<div class="modal" [ngClass]="{'is-active': deleteChat !== undefined}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-primary">{{'LIST.MODAL_TITLE' | translate}}</p>
      <button class="delete" aria-label="close" (click)="deleteChat = undefined"></button>
    </header>
    <section class="modal-card-body">
      <p [innerHTML]="'LIST.MODAL_TEXT' | translate: {chatDelete: deleteChat?.name_column}">
      </p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-danger" (click)="confirmDeleteChat()">{{'GENERIC.ACCEPT' | translate}}</button>
        <button class="button" (click)="deleteChat = undefined">{{'GENERIC.CANCEL' | translate}}</button>
      </div>
    </footer>
  </div>
</div>

<div class="modal" [ngClass]="{'is-active': modalCreateChat}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-primary">{{(editChat ? 'LIST.MODAL_EDIT' : 'LIST.MODAL_CREATE') | translate}}</p>
      <button class="delete" aria-label="close" (click)="modalCreateChat = false"></button>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">{{'LIST.INPUT_MODAL' | translate}}</label>
        <div class="control">
          <input class="input" type="text" [(ngModel)]="chatName">
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-primary" (click)="createChat()">{{'GENERIC.ACCEPT' | translate}}</button>
        <button class="button" (click)="modalCreateChat = false">{{'GENERIC.CANCEL' | translate}}</button>
      </div>
    </footer>
  </div>
</div>